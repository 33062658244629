import React, { FC } from 'react';

import { CssBaseline, ThemeName } from '@involve-software/uikit';
import * as Sentry from '@sentry/react';

import { Matcher } from 'navi';

import { InternalErrorBlock } from 'components/internalError';

import { AuthServiceProvider } from 'modules/authService/provider';

import { Routes } from 'router/routes';
import { Theme } from 'themes/contracts';

import GlobalStyles from './GlobalStyles';
import ScThemeProvider from './scThemeProvider';
import { ThemeProvider } from './themeProvider';

console.log(APP_VERSION);
const themeKey = (process.env.REACT_APP_THEME as ThemeName) || 'kvitum';

const App: FC<{ theme: Theme; routes: Matcher<any> }> = ({ theme, routes }) => {
  return (
    <ThemeProvider theme={theme} themeKey={themeKey}>
      <ScThemeProvider theme={theme}>
        <GlobalStyles theme={theme} />
        <CssBaseline />
        <Sentry.ErrorBoundary
          fallback={
            <section>
              <InternalErrorBlock />
            </section>
          }
        >
          <AuthServiceProvider>
            <Routes routes={routes} />
          </AuthServiceProvider>
        </Sentry.ErrorBoundary>
      </ScThemeProvider>
    </ThemeProvider>
  );
};

export default Sentry.withProfiler(App);

import React, { FC, useContext, useState } from 'react';

import { useTheme } from '@involve-software/uikit';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-navi';

import { useOldVersionHref } from 'hooks/translation';
import { useComponentVisible } from 'hooks/visibility';

import { showTooltip } from 'helpers/tooltip';
import { translationFactory } from 'helpers/trans';

import {
  ScAccountDropDown,
  ScAccountInfo,
  ScAccountNumber,
  ScAvatarIcon,
  ScHeaderBlock,
} from 'components/header/styled';
import Loader from 'components/loader';
import { ScShutter } from 'components/shutter/styled';

import { AccountContext } from 'modules/accountContext';
import { AuthServiceContext } from 'modules/authService/context';
import { TooltipTypes } from 'modules/globalTooltip';

const AccountBlock: FC = () => {
  const { email } = useContext(AccountContext);

  const { images } = useTheme();

  const { ref, openerRef, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  function toggleDropdown() {
    setIsComponentVisible((i) => !i);
  }

  const authService = useContext(AuthServiceContext);

  const [logoutPending, setLogoutPending] = useState(false);
  async function logout() {
    if (logoutPending) return;

    setLogoutPending(true);

    try {
      await authService.logout();
    } catch (e) {
      setLogoutPending(false);

      showTooltip({
        type: TooltipTypes.ERROR,
        translate: true,
        body: translationFactory('common:serverError.unknown'),
      });
    }
  }

  const { t } = useTranslation();

  return (
    <>
      <Loader on={logoutPending} />
      <a href={`mailto:${email}`} className="email">
        {email}
      </a>
      <ScAccountNumber
        ref={openerRef}
        onClick={toggleDropdown}
        open={isComponentVisible}
      >
        <ScAvatarIcon />
        {isComponentVisible && <ScShutter />}
      </ScAccountNumber>
      {isComponentVisible ? (
        <ScAccountDropDown ref={ref}>
          <li>
            <a href={`mailto:${email}`}>
              <images.emailIcon.component />
              {email}
            </a>
          </li>
          <li onClick={logout}>
            <images.logoutIcon.component />
            {t('common:header.logout')}
          </li>
        </ScAccountDropDown>
      ) : null}
    </>
  );
};

export const AnonymousHeader = () => {
  const { images } = useTheme();
  return (
    <ScHeaderBlock anonymous>
      <header className="anonymous-header">
        <Link href="/" className="logo">
          <img src={images.logo.path} alt="" />
        </Link>
      </header>
    </ScHeaderBlock>
  );
};

export interface AuthenticatedHeaderProps {
  withAccountOptions?: boolean;
}

export const AuthenticatedHeader: FC<AuthenticatedHeaderProps> = ({
  withAccountOptions = true,
}) => {
  const { t } = useTranslation();

  const { images } = useTheme();

  const oldVersion = useOldVersionHref(process.env.REACT_APP_OLD_VERSION_URL);

  return (
    <ScHeaderBlock>
      <header>
        <Link href="/" className="logo">
          <img src={images.logo.path} alt="" />
        </Link>

        <ScAccountInfo>
          {oldVersion && (
            <a className="link-old-version" href={oldVersion} target="_self">
              {t('common:header.oldVersion')}
            </a>
          )}

          {withAccountOptions && <AccountBlock />}
        </ScAccountInfo>
      </header>
    </ScHeaderBlock>
  );
};

import React from 'react';

import { useTheme } from '@involve-software/uikit';

import { NotFoundError } from 'navi';
import { Trans } from 'react-i18next';
import { Link } from 'react-navi';

import { ScNotFoundBlock, ScWrap } from 'components/block/styled';
import { ScButton } from 'components/button/styled';
import Footer from 'components/footer';
import { AuthenticatedHeader } from 'components/header';

export const NotFoundBlock = ({ error }: { error: NotFoundError }) => {
  const { images } = useTheme();
  return (
    <ScNotFoundBlock>
      <div className="notfound-effect">
        <div>
          <div>
            <img src={images.loader.path} alt="" />
          </div>
        </div>
      </div>
      <Trans i18nKey="common:notFound">
        {/*@ts-ignore*/}
        <h2>Page {{ page: error.pathname }} was not found</h2>
        <p>
          Maybe you mistyped URL or this page does not longer exist.
          <br />
        </p>
        <p className="text-grey">
          If you believe that this page should exist - contact our support team
          <a href={`mailto:${process.env.REACT_APP_SUPPORT_MAIL}`}>
            {/*@ts-ignore*/}
            {{ support: process.env.REACT_APP_SUPPORT_MAIL }}
          </a>
        </p>
        <ScWrap noPadding>
          <Link className="button-link" href="/">
            <ScButton primary>Back to main</ScButton>
          </Link>
        </ScWrap>
      </Trans>
    </ScNotFoundBlock>
  );
};

export const NotFoundLayout = (error: NotFoundError) => (
  <>
    <AuthenticatedHeader withAccountOptions={false} />

    <main>
      <section>
        <NotFoundBlock error={error} />
      </section>
    </main>

    <Footer />
  </>
);
